import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../../../src/components/upload-panel/Header";
import UploadData from "../../../src/components/upload-panel/UploadData";
import DownloadData from "../../../src/components/download-panel/DownloadData";
import { Tabs, Tab, Box } from "@mui/material";
import "./FileProcess.css";

function FileProcess() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [can_access, setCanAccess] = useState(false);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    const checkAccess = () => {
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      const apiUrl = `${baseUrl}/can_access`;
      axios.get(apiUrl, { headers: { "Content-Type": "application/json" } }).then(
        (response) => {
          if (response.data && response.data.can_access) {
            return setCanAccess(true);
          } 
          setCanAccess(false);
        }
      ).catch((error) => {
        console.error("Error checking access: ", error.message);
      });
    }
    checkAccess();
  }, []);

  if (!can_access) {
    return (
      <div className="App">
        <Header />
        <main className="container py-5">
          <div className="card mat-elevation-z3">
            <div className="card-body">
              <h1>Unauthorized Access</h1>
              <p>You are not authorized to access this page.</p>
            </div>
          </div>
        </main>
      </div>
    );
  }

  return (
    <div className="App">
      <Header />
      <main className="container py-5">
        <div className="card mat-elevation-z3">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="basic tabs example"
              variant="fullWidth"
              className="tab-no-outline"
            >
              <Tab
                icon={<span className="material-icons">upload</span>}
                label="Upload"
              />
              <Tab
                icon={<span className="material-icons">download</span>}
                label="Download"
              />
            </Tabs>
          </Box>
          <div className="card-body">
            {selectedTab === 0 && <UploadData />}
            {selectedTab === 1 && <DownloadData />}
          </div>
        </div>
      </main>
    </div>
  );
}

export default FileProcess;
