// App.js
import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PlatformToolbar from "@apex/react-toolkit/components/PlatformToolbar";
import icon from "../src/images/file-export-solid.svg";
import FileProcess from "../src/components/FileProcess/FileProcess";

function App() {
  return (
    <Router>
      <PlatformToolbar
        icon={
          <img
            src={icon}
            className="mb-1"
            alt="apex icon"
            style={{ width: "24px", height: "24px" }}
          />
        }
      />
      <Routes>
        <Route path="/" element={<FileProcess />} />
      </Routes>
    </Router>
  );
}

export default App;
